import mock from '../mock'

const data = {
  // knowledge base
  services: [
    {
      id: 1,
      category: '/services/vehicles/search?serviceType=rc-renewal',
      img: require('@src/assets/images/illustration/sales.svg').default,
      title: 'Renew Registration Certificate',
      desc: 'Renew Vehicle Registration Certificate Description.',
      icon: 'FaFileSignature',
      outlinecolor: 'success'
    },
    {
      id: 2,
      category: '/services/driving/search?serviceType=driving-renewal',
      img: require('@src/assets/images/illustration/marketing.svg').default,
      title: 'Renew Driving License',
      desc: 'Renew Driving License Description.',
      icon: 'FaIdCard',
      outlinecolor: 'secondary'
    },
    {
      id: 3,
      category: '/services/learner/search?serviceType=learner-renewal',
      img: require('@src/assets/images/illustration/api.svg').default,
      title: 'Renew Learner License',
      desc: 'Renew Learner License Description.',
      icon: 'FaUserCircle',
      outlinecolor: 'success'
    },
    {
      id: 4,
      category: '/services/vehicles/search?serviceType=rc-replacement',
      img: require('@src/assets/images/illustration/personalization.svg').default,
      title: 'Replace Registration Certificate',
      desc: 'Replace Registration Certificate Description.',
      icon: 'FaRegFile',
      outlinecolor: 'secondary'
    },
    {
      id: 5,
      category: '/services/driving/search?serviceType=driving-replacement',
      img: require('@src/assets/images/illustration/email.svg').default,
      title: 'Replace Driving License',
      desc: 'Replace Driving License Description.',
      icon: 'FaRegIdCard',
      outlinecolor: 'success'
    },
    {
      id: 6,
      category: '/services/learner/search?serviceType=learner-replacement',
      img: require('@src/assets/images/illustration/demand.svg').default,
      title: 'Replace Learner License',
      desc: 'Replace Learner License Description.',
      icon: 'FaRegUserCircle',
      outlinecolor: 'secondary'
    },
    {
      id: 7,
      category: '/services/vehicles/search?serviceType=road-worthiness',
      img: require('@src/assets/images/illustration/marketing.svg').default,
      title: 'Road Worthiness/Fitness Certificate',
      desc: 'Road Worthiness Certificate (Fitness Test)',
      icon: 'FaCarSide',
      outlinecolor: 'success'
    },
    {
      id: 8,
      category: '/services/vehicles/search?serviceType=ownership-transfer',
      img: require('@src/assets/images/illustration/sales.svg').default,
      title: 'Transfer Vehicle Ownership',
      desc: 'Vehicle Ownership Transfer Description.',
      icon: 'FaPeopleArrows',
      outlinecolor: 'secondary'
    },
    {
      id: 9,
      category: '/services/offence/search',
      img: require('@src/assets/images/illustration/personalization.svg').default,
      title: 'Pay Fine',
      desc: 'Pay Fine (Offence - Traffice Violation).',
      icon: 'FaBan',
      outlinecolor: 'success'
    },
    {
      id: 10,
      category: '/services/driving-test/book-test',
      img: require('@src/assets/images/illustration/email.svg').default,
      title: 'Book A Driving Test',
      desc: 'Book A Driving Test',
      icon: 'FaRoute',
      outlinecolor: 'secondary'
    },
    {
      id: 11,
      category: '/services/driving-test/e-test/login',
      img: require('@src/assets/images/illustration/email.svg').default,
      title: 'eTest Login',
      desc: 'ETest Login',
      icon: 'FaChalkboardTeacher',
      outlinecolor: 'secondary'
    },
    {
      id: 12,
      category: '/pay-online/application/search',
      img: require('@src/assets/images/illustration/demand.svg').default,
      title: 'Payment through Application No',
      desc: 'Make Payment through your application number.',
      icon: 'FaRegCreditCard',
      outlinecolor: 'secondary'
    },
    {
      id: 13,
      category: '/services/learner/new',
      img: require('@src/assets/images/illustration/alpha-l-box.svg').default,
      title: 'New Learner Application',
      desc: 'New Learner Application Description.',
      icon: 'FaIdCard',
      outlinecolor: 'success'
    }
    // {
    //   id: 13,
    //   category: '/misc/maintenance',
    //   img: require('@src/assets/images/illustration/alpha-l-box.svg').default,
    //   title: 'New Learner Application',
    //   desc: 'New Learner Application Description.',
    //   icon: 'FaIdCard',
    //   outlinecolor: 'success'
    // }
  ]
}

mock.onGet('/services/data').reply((config) => {
  const { q = '' } = config.params
  const queryLowered = q.toLowerCase()

  const filteredData = data.services.filter((item) => item.title.toLowerCase().includes(queryLowered))

  return [200, filteredData]
})
