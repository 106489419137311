// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import DrivingService from '@services/driving'

export const getProfessionalDrivingLicense = createAsyncThunk('driving/getProfessionalDrivingLicense', async (id, { rejectWithValue }) => {
  try {
    const response = await DrivingService.getProfessionalDrivingLicense(id)

    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getOrdinaryDrivingLicense = createAsyncThunk('driving/getOrdinarylDrivingLicense', async (id, { rejectWithValue }) => {
  try {
    const response = await DrivingService.getOrdinaryDrivingLicense(id)

    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const DrivingSlice = createSlice({
  name: 'driving',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedDriving: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfessionalDrivingLicense.fulfilled, (state, action) => {
        state.selectedDriving = action.payload
        state.loading = false
      })
      .addCase(getProfessionalDrivingLicense.pending, (state) => {
        state.selectedDriving = null
        state.loading = true
      })
      .addCase(getProfessionalDrivingLicense.rejected, (state) => {
        state.selectedDriving = null
        state.loading = false
      })
      .addCase(getOrdinaryDrivingLicense.fulfilled, (state, action) => {
        state.selectedDriving = action.payload
        state.loading = false
      })
      .addCase(getOrdinaryDrivingLicense.pending, (state) => {
        state.selectedDriving = null
        state.loading = true
      })
      .addCase(getOrdinaryDrivingLicense.rejected, (state) => {
        state.selectedDriving = null
        state.loading = false
      })
  }
})

export default DrivingSlice.reducer
