import { lazy } from 'react'

const NdiServices = lazy(() => import('../../views/pages/ndi-services'))
const InitiatePresentProofRequest = lazy(() => import('../../views/ndi/InitiatePresentProofRequest'))
// const LearnerNewApplication = lazy(() => import('../../views/learner-license/new-learner/index'))

const NdiServiceRoutes = [
  {
    element: <NdiServices />,
    path: '/ndi-services'
  },
  {
    element: <InitiatePresentProofRequest />,
    path: '/ndi-services/:credentialType'
  },
  {
    element: <NdiServices />,
    path: '/services/learner/application/:id/summary'
  }
]

export default NdiServiceRoutes
