import api from '@services'

const servicePrefixPis = process.env.REACT_APP_PIS_BASE_URL ?? ''
const serviceBaseUrl = process.env.REACT_APP_LEARNER_LICENSE_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/learner-license/api`

export const searchLearnerByLearnerNumber = async (searchTerm) => {
  const response = await api.get(`${servicePrefix}/learner-licenses/search-learner-by-learner-number/${searchTerm}`)
  return response.data
}

export const searchLearnerHolderID = async (searchTerm) => {
  const response = await api.get(`${servicePrefixPis}/svc/personal-information/api/personal-informations/info/${searchTerm}`)
  return response.data
}

export const searchLearnerByHolderId = async (searchTerm) => {
  const response = await api.get(`${servicePrefix}/learner-licenses/search/${searchTerm}`)
  return response.data
}

const getLearner = async (id) => {
  try {
    const response = await api.get(`${servicePrefix}/learner-licenses/${id}`)
    return response.data
  } catch (err) {
    console.log(err)
  }
}

export const searchLearnerByCid = async (cidNumber) => {
  const response = await api.get(`${servicePrefix}/learner-licenses/search-by-cid/${cidNumber}`)
  return response.data
}

export const newLearnerRenewalApplication = async (params) => {
  console.log('params')
  console.log(params)
  const response = await api.post(`${servicePrefix}/renewals/client`, params)
  return response.data
}

export const getLearnerRenewalApplication = async (id) => {
  const response = await api.get(`${servicePrefix}/renewals/${id}`)
  return response.data
}

export const getAllOfficeLocations = async () => {
  const response = await api.get(`${servicePrefix}/office-locations/all`)
  return response.data
}

export const newLearnerReplacementApplication = async (params) => {
  const response = await api.post(`${servicePrefix}/replacements/client`, params)
  return response.data
}

export const getLearnerReplacementApplication = async (id) => {
  const response = await api.get(`${servicePrefix}/replacements/${id}`)
  return response.data
}

export const getLearnerRegistrationByApplicationNo = async (applicationNo) => {
  const response = await api.get(`${servicePrefix}/online-learner-applications/registration-by-application-number/${applicationNo}`)
  return response.data
}

//fetch application number details
export const getLearnerRenewalByApplicationNo = async (applicationNo) => {
  const response = await api.get(`${servicePrefix}/renewals/renewal-application-number/${applicationNo}`)
  return response.data
}

export const getLearnerReplacementByApplicationNo = async (applicationNo) => {
  const response = await api.get(`${servicePrefix}/replacements/replacement-application-number/${applicationNo}`)
  return response.data
}

//add pis type of individual
const addPisIndividual = async (individualInfo) => {
  const response = await api.post(`${servicePrefix}/pis-applications`, individualInfo)
  return response.data
}

const addLearnerApplication = async (application) => {
  const response = await api.post(`${servicePrefix}/online-learner-applications`, application)
  return response.data
}

const uploadPisAvatar = async (data) => {
  const pisId = data.get('pisId')
  const response = await api.post(`${servicePrefix}/online-learner-applications/avatar/${pisId}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

const updateLearnerLicenseAttachment = async (data) => {
  const pisId = data.get('pisId')
  const response = await api.post(`${servicePrefix}/online-learner-applications/attachments/${pisId}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

//getAllDriveTypes
const driveTypeAll = async () => {
  const response = await api.get(`${servicePrefix}/drive-types/all`)
  return response
}

export const getOnlineApplicationById = async (id) => {
  const response = await api.get(`${servicePrefix}/online-learner-applications/${id}`)
  return response.data
}

const LearnerService = {
  //vehicles
  searchLearnerByLearnerNumber,
  searchLearnerHolderID,
  searchLearnerByHolderId,
  getLearner,
  searchLearnerByCid,
  getAllOfficeLocations,
  newLearnerRenewalApplication,
  getLearnerRenewalApplication,
  newLearnerReplacementApplication,
  getLearnerRegistrationByApplicationNo,
  getLearnerReplacementApplication,
  getLearnerRenewalByApplicationNo,
  getLearnerReplacementByApplicationNo,
  addPisIndividual,
  driveTypeAll,
  addLearnerApplication,
  uploadPisAvatar,
  updateLearnerLicenseAttachment,
  getOnlineApplicationById
}

export default LearnerService
