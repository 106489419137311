import { LogIn, Package, Home, HelpCircle, Compass, CreditCard, Layers } from 'react-feather'
import NdiLogoNoBg from '@src/assets/images/ndi/ndi-logo-bg.png'

const NDIIcon = ({ src, size }) => <img src={src} alt="icon" style={{ width: size, height: size, marginRight: '10px' }} />


export default [
  {
    id: 'home',
    title: 'Home',
    icon: <Home size={20} />,
    navLink: '/'
  },
  {
    id: 'services',
    title: 'Services',
    icon: <Package size={20} />,
    navLink: '/services'
  },
  {
    id: 'pay-online',
    title: 'Pay Online',
    icon: <CreditCard size={20} />,
    navLink: '/pay-online/application/search'
  },
  {
    id: 'generate-receipt',
    title: 'Generate Receipt',
    icon: <Compass size={20} />,
    navLink: '/payments/receipts'
  },
  {
    id: 'etest-login',
    title: 'eTest Login',
    icon: <LogIn size={20} />,
    navLink: '/services/driving-test/e-test/login'
  },
  // {
  //   id: 'faq',
  //   title: 'Frequently Asked Questions',
  //   icon: <HelpCircle size={20} />,
  //   navLink: '/faq'
  // },
  {
    id: 'NDI Services',
    title: 'National Digital Identity',
    icon: <NDIIcon size={20} src={NdiLogoNoBg} />,
    navLink: '/ndi-services'
  }
]
