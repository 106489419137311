// ** React Imports
import { Fragment, lazy } from 'react'
import { Navigate } from 'react-router-dom'
// ** Layouts
import BlankLayout from '@layouts/BlankLayout'
import VerticalLayout from '@src/layouts/VerticalLayout'
import HorizontalLayout from '@src/layouts/HorizontalLayout'
import LayoutWrapper from '@src/@core/layouts/components/layout-wrapper'

// ** Route Components
import PublicRoute from '@components/routes/PublicRoute'

import PagesRoutes from './Pages'
import FormsRoutes from './Forms'
import VehicleRoutes from './Vehicle'
import LearnerRoutes from './Learner'
import DrivingRoutes from './Driving'
import DrivingTestRoutes from './DrivingTest'
import PaymentReceiptRoutes from './PaymentReceipt'
import ApplicationNumber from './ApplicationNumber'
import OffenceRoutes from './Offence'
import NdiServiceRoutes from './NdiServices'

// ** Utils
import { isObjEmpty } from '@utils'

const getLayout = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />,
  horizontal: <HorizontalLayout />
}

// ** Document title
const TemplateTitle = '%s - RSTA'

// ** Default Route
const DefaultRoute = '/'

const HomePage = lazy(() => import('../../views/pages/home'))
const SecondPage = lazy(() => import('../../views/SecondPage'))
const Login = lazy(() => import('../../views/auth/Login'))
const LoginTabs = lazy(() => import('../../views/auth'))
const LoginWithNdi = lazy(() => import('../../views/auth/LoginWithNdi'))
const RegisterWithNdi = lazy(() => import('../../views/auth/RegisterWithNdi'))

const Register = lazy(() => import('../../views/auth/Register'))
const VerifyUser = lazy(() => import('../../views/auth/VerifyUser'))
const SetNewPin = lazy(() => import('../../views/auth/SetNewPin'))
const ForgotPassword = lazy(() => import('../../views/ForgotPassword'))
const Error = lazy(() => import('../../views/Error'))
const ServicesPage = lazy(() => import('../../views/pages/services'))
const Maintenance = lazy(() => import('../../views/pages/misc/Maintenance'))

const BasicRoutes = [
  {
    path: '/',
    index: true,
    element: <HomePage />
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/login-tabs',
    element: <LoginTabs />
  },
  {
    path: '/register',
    element: <Register />
  },
  {
    path: '/verify',
    element: <VerifyUser />
  },
  {
    path: '/set-new-pin',
    element: <SetNewPin />
  },
  {
    path: '/login-with-ndi',
    element: <LoginWithNdi />
  },
  {
    path: '/register-with-ndi',
    element: <RegisterWithNdi />
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/error',
    element: <Error />,
    meta: {
      layout: 'blank'
    }
  }
]

// ** Merge Routes
const Routes = [...BasicRoutes, ...PagesRoutes, ...FormsRoutes, ...VehicleRoutes, ...LearnerRoutes, ...DrivingRoutes, ...DrivingTestRoutes, ...PaymentReceiptRoutes, ...ApplicationNumber, ...OffenceRoutes, ...NdiServiceRoutes]

const getRouteMeta = (route) => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta }
    } else {
      return {}
    }
  }
}

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
  const LayoutRoutes = []

  if (Routes) {
    Routes.filter((route) => {
      let isBlank = false
      // ** Checks if Route layout or Default layout matches current layout
      if ((route.meta && route.meta.layout && route.meta.layout === layout) || ((route.meta === undefined || route.meta.layout === undefined) && defaultLayout === layout)) {
        const RouteTag = PublicRoute

        // ** Check for public or private route
        if (route.meta) {
          route.meta.layout === 'blank' ? (isBlank = true) : (isBlank = false)
        }
        if (route.element) {
          const Wrapper =
            // eslint-disable-next-line multiline-ternary
            isObjEmpty(route.element.props) && isBlank === false
              ? // eslint-disable-next-line multiline-ternary
                LayoutWrapper
              : Fragment

          route.element = (
            <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
              <RouteTag route={route}>{route.element}</RouteTag>
            </Wrapper>
          )
        }

        // Push route to LayoutRoutes
        LayoutRoutes.push(route)
      }
      return LayoutRoutes
    })
  }
  return LayoutRoutes
}

const getRoutes = (layout) => {
  const defaultLayout = layout || 'vertical'
  const layouts = ['vertical', 'horizontal', 'blank']

  const AllRoutes = []

  layouts.forEach((layoutItem) => {
    const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout)

    AllRoutes.push({
      path: '/',
      element: getLayout[layoutItem] || getLayout[defaultLayout],
      children: LayoutRoutes
    })
  })
  return AllRoutes
}

export { DefaultRoute, TemplateTitle, Routes, getRoutes }
