import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// ** Reducers Imports
import layout from './layout'
import navbar from './navbar'
import auth from './authentication'
import vehicles from '@src/views/vehicles/store'
import learner from '@src/views/learner-license/store'
import driving from '@src/views/driving-license/store'
import makePayment from '@src/views/payments/store'
import rma from '@src/views/payments/rma/store'
import eTest from '@src/views/driving-test/e-test/store'

const rootReducer = combineReducers({ auth, navbar, layout, vehicles, makePayment, rma, learner, driving, eTest })

// Configure Redux Persist
const persistConfig = {
  key: 'root',
  storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default persistedReducer
