import api from '@services'

const serviceBaseUrl = process.env.REACT_APP_PAYMENT_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/payment/api`

export const getPaymentDetailsByApplicationNo = async (applicationNo) => {
  const response = await api.get(`${servicePrefix}/payments/${applicationNo}`)
  return response.data
}

export const sendAuthorizationRequest = async (applicationNo) => {
  const response = await api.post(`${servicePrefix}/payments/${applicationNo}/make-payment/authorization-request`)
  return response.data
}

export const postAccountInquiryRequest = async (applicationNo, data) => {
  console.log('data', data)
  const response = await api.post(`${servicePrefix}/payments/${applicationNo}/make-payment/account-inquiry-request`, data)
  return response.data
}

export const postSendDebitRequest = async (applicationNo, data) => {
  const response = await api.post(`${servicePrefix}/payments/${applicationNo}/make-payment/debit-request`, data)
  return response.data
}

export const generatePaymentReceipt = async (data) => {
  const response = await api.post(`${servicePrefix}/payments/generate-receipt`, data)
  return response.data
}
