import { lazy } from 'react'
import { Redirect } from 'react-router-dom'

const HomePage = lazy(() => import('../../views/pages/home'))
const ServicesPage = lazy(() => import('../../views/pages/services'))
const Faq = lazy(() => import('../../views/pages/faq'))
const Maintenance = lazy(() => import('../../views/pages/misc/Maintenance'))

const PagesRoutes = [
  {
    path: '/',
    element: <HomePage />
  },
  {
    path: '/misc/maintenance',
    element: <Maintenance />
  },
  {
    path: '/services',
    element: <ServicesPage />
  }
  // {
  //   path: '/faq',
  //   element: <Faq />
  // }
]

export default PagesRoutes
