// ** React Imports
import { Link } from 'react-router-dom'
// import { useEffect, useState } from 'react'

// ** Custom Components
import Avatar from '@components/avatar'

// ** Third Party Components
import { User, Mail, CheckSquare, MessageSquare, Settings, CreditCard, HelpCircle, Power } from 'react-feather'
import { AiOutlineIdcard, AiOutlineCar, AiOutlineUser } from 'react-icons/ai'
// ** Reactstrap Imports
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap'

// ** Default Avatar Image
// import defaultAvatar from '@src/assets/images/portrait/small/avatar-s-11.jpg'
import defaultAvatar from '@src/assets/images/avatars/avatar-blank.png'

// ** Custom Components
// import Avatar from '@components/avatar'

// ** Utils
import { isObjEmpty } from '@utils'

// ** Store & Actions
import { useSelector, useDispatch } from 'react-redux'
import { handleLogout } from '@store/authentication'

// import {  } from 'react-redux'

const UserDropdown = () => {
  // ** Store Vars
  const dispatch = useDispatch()

  const { userData } = useSelector((state) => state.auth)

  // useEffect(() => {
  //   if (isUserLoggedIn() !== null) {
  //     setUserData(JSON.parse(localStorage.getItem('userData')))
  //   }
  //   console.log('Store', store.userData)
  // }, [store])

  //** Vars
  const userAvatar = (userData && userData.avatar) || defaultAvatar

  return (
    <>
      {!isObjEmpty(userData) ? (
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle href="/" tag="a" className="nav-link dropdown-user-link" onClick={(e) => e.preventDefault()}>
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name fw-bold">{userData && userData['name']}</span>
              <span className="user-status">{userData && userData['roleType']?.toUpperCase()}</span>
            </div>
            <Avatar img={`https://s3.eralis.rsta.gov.bt/eralis/${userAvatar}`} imgHeight="40" imgWidth="40" status="online" />
          </DropdownToggle>
          <DropdownMenu end>
            <DropdownItem tag={Link} to="/" onClick={(e) => e.preventDefault()}>
              <AiOutlineUser size={14} className="me-75" />
              <span className="align-middle">Profile</span>
            </DropdownItem>
            <DropdownItem tag={Link} to="/" onClick={(e) => e.preventDefault()}>
              <AiOutlineCar size={14} className="me-75" />
              <span className="align-middle">My Vehicles</span>
            </DropdownItem>
            <DropdownItem tag={Link} to="/" onClick={(e) => e.preventDefault()}>
              <AiOutlineIdcard size={14} className="me-75" />
              <span className="align-middle">My License</span>
            </DropdownItem>
            {/* <DropdownItem tag={Link} to="/" onClick={(e) => e.preventDefault()}>
          <Mail size={14} className="me-75" />
          <span className="align-middle">Inbox</span>
        </DropdownItem> */}
            {/* <DropdownItem tag={Link} to="/" onClick={(e) => e.preventDefault()}>
          <CheckSquare size={14} className="me-75" />
          <span className="align-middle">Tasks</span>
        </DropdownItem>
        <DropdownItem tag={Link} to="/" onClick={(e) => e.preventDefault()}>
          <MessageSquare size={14} className="me-75" />
          <span className="align-middle">Chats</span>
        </DropdownItem> */}
            <DropdownItem divider />
            <DropdownItem tag={Link} to="/pages/" onClick={(e) => e.preventDefault()}>
              <Settings size={14} className="me-75" />
              <span className="align-middle">Settings</span>
            </DropdownItem>
            {/* <DropdownItem tag={Link} to="/" onClick={(e) => e.preventDefault()}>
          <CreditCard size={14} className="me-75" />
          <span className="align-middle">Pricing</span>
        </DropdownItem>
        <DropdownItem tag={Link} to="/" onClick={(e) => e.preventDefault()}>
          <HelpCircle size={14} className="me-75" />
          <span className="align-middle">FAQ</span>
        </DropdownItem> */}
            <DropdownItem
              tag={Link}
              to="/login"
              onClick={() => {
                dispatch(handleLogout())
                navigate('/')
              }}
            >
              <Power size={14} className="me-75" />
              <span className="align-middle">Logout</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ) : (
        <div className="" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/login">
                Login
              </Link>
            </li>
            <li className="nav-item">|</li>
            <li className="nav-item">
              <Link className="nav-link" to="/register">
                Register
              </Link>
            </li>
          </ul>
        </div>
      )}
    </>
  )
}

export default UserDropdown
