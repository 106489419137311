import api from '@services'

const servicePrefixPis = process.env.REACT_APP_PIS_BASE_URL ?? ''

const serviceBaseUrl = process.env.REACT_APP_DRIVING_LICENSE_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/driving-license/api`

export const searchDrivingLicenseByNo = async (searchTerm) => {
  const response = await api.get(`${servicePrefix}/licenses/search/${searchTerm}`)
  return response.data
}

export const searchDrivingLicenseByCid = async (cid) => {
  const response = await api.get(`${servicePrefix}/licenses/search-by-cid/${encodeURIComponent(cid)}`)
  return response.data
}

export const searchDrivingLicense = async (searchTerm) => {
  const response = await api.get(`${servicePrefix}/licenses/search/${searchTerm}`)
  return response.data
}

export const searchDriverByHolderId = async (searchTerm) => {
  //for CID search
  const response = await api.get(`${servicePrefix}/ordinary-driving-license/search-license/${searchTerm}`)
  return response.data
}

export const searchDriverLicenseHolderID = async (searchTerm) => {
  const response = await api.get(`${servicePrefixPis}/svc/personal-information/api/personal-informations/info/${searchTerm}`)
  return response.data
}

const getOrdinaryDrivingLicense = async (id) => {
  try {
    const response = await api.get(`${servicePrefix}/ordinary-driving-license/${id}`)
    console.log('id', 'what', response.data)
    return response.data
  } catch (err) {
    console.log(err)
  }
}

const getProfessionalDrivingLicense = async (id) => {
  try {
    const response = await api.get(`${servicePrefix}/professional-driving-license/${id}`)
    return response.data
  } catch (err) {
    console.log(err)
  }
}

export const newDrivingRenewalApplication = async (params) => {
  console.log(params)
  const response = await api.post(`${servicePrefix}/renewals/client`, params)
  return response.data
}

export const getDrivingRenewalApplication = async (id) => {
  const response = await api.get(`${servicePrefix}/renewals/${id}`)
  return response.data
}

export const getAllOfficeLocations = async () => {
  const response = await api.get(`${servicePrefix}/office-locations/all`)
  return response.data
}

export const newDrivingReplacementApplication = async (params) => {
  const response = await api.post(`${servicePrefix}/replacements/client`, params)
  return response.data
}

export const getDrivingReplacementApplication = async (id) => {
  const response = await api.get(`${servicePrefix}/replacements/${id}`)
  return response.data
}

//fetch application number details
export const getDrivingLicenseRegistrationByApplicationNo = async (applicationNo) => {
  const response = await api.get(`${servicePrefix}/applications/by-application-no/${applicationNo}`)
  return response.data
}

export const getDrivingRenewalByApplicationNo = async (applicationNo) => {
  const response = await api.get(`${servicePrefix}/renewals/renewal-application-number/${applicationNo}`)
  return response.data
}

export const getDrivingReplacementByApplicationNo = async (applicationNo) => {
  const response = await api.get(`${servicePrefix}/replacements/replacement-application-number/${applicationNo}`)
  return response.data
}

const LearnerService = {
  //vehicles
  searchDrivingLicense,
  searchDriverByHolderId,
  getProfessionalDrivingLicense, //added
  getOrdinaryDrivingLicense,
  getAllOfficeLocations,
  getDrivingLicenseRegistrationByApplicationNo,
  newDrivingRenewalApplication,
  getDrivingRenewalApplication,
  newDrivingReplacementApplication,
  getDrivingReplacementApplication,
  searchDriverLicenseHolderID,
  getDrivingRenewalByApplicationNo,
  getDrivingReplacementByApplicationNo
}

export default LearnerService
