import { lazy } from 'react'

const SearchApplication = lazy(() => import('../../views/application-payment/search'))
const ApplicationPayment = lazy(() => import('../../views/application-payment/application'))

const ApplicationNumberRoutes = [
  {
    element: <SearchApplication />,
    path: '/pay-online/application/search'
  },
  {
    element: <ApplicationPayment />,
    path: '/pay-online/application/:applicationNo/make-payment'
  }
]

export default ApplicationNumberRoutes
