// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import DrivingTest from '@services/driving-test'
// import { randomizeQuestions } from '@utils'

export const eTestLogin = createAsyncThunk('eTest/eTestLogin', async (data, { rejectWithValue }) => {
  try {
    const response = await DrivingTest.eTestLogin(data)
    return response
  } catch (err) {
    if (!err.response) {
      throw err
    }
    return rejectWithValue(err.response.data)
  }
})

export const startTheoryTest = createAsyncThunk('eTest/startTheoryTest', async (apiKey, { rejectWithValue }) => {
  try {
    const response = await DrivingTest.startTheoryTest(apiKey)
    return response
  } catch (err) {
    if (!err.response) {
      throw err
    }
    return rejectWithValue(err.response.data)
  }
})

export const getTheoryTestQuestions = createAsyncThunk('eTest/getQuestions', async (payload) => {
  const response = await DrivingTest.getTheoryTestQuestions(payload.theoryTestId, payload.apiKey)
  console.log('where', response)
  return response
})

export const getTheoryTestScore = createAsyncThunk('eTest/getTheoryTestScore', async (payload, { rejectWithValue }) => {
  try {
    const response = await DrivingTest.getTheoryTestScore(payload.theoryTestId, payload.apiKey)
    return response
  } catch (err) {
    if (!err.response) {
      throw err
    }
    return rejectWithValue(err.response.data)
  }
})

export const ETestSlice = createSlice({
  name: 'eTest',
  initialState: {
    theoryTest: null,
    questions: [],
    testCandidate: {},
    answers: [],
    selectedQuestion: null,
    currentQuestionIndex: 0,
    previousQuestionIndex: 0,
    nextQuestionIndex: 0,
    loading: true,
    errors: null,
    eTestUserToken: null
  },
  reducers: {
    nextQuestion: (state, action) => {
      state.currentQuestionIndex = action.payload.nextQuestionIndex
      state.previousQuestionIndex = action.payload.previousQuestionIndex
      state.selectedQuestion = state.questions[state.currentQuestionIndex]
    },
    previousQuestion: (state, action) => {
      state.currentQuestionIndex = action.payload.previousQuestionIndex
      state.previousQuestionIndex = action.payload.nextQuestionIndex
      state.selectedQuestion = state.questions[state.currentQuestionIndex]
    },
    setAnswer: (state, action) => {
      state.answers[state.currentQuestionIndex] = action.payload
      console.log(state.answers[state.currentQuestionIndex])
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(startTheoryTest.fulfilled, (state, action) => {
        state.loading = false
        state.theoryTest = action.payload
        state.currentQuestionIndex = 0
        state.previousQuestionIndex = 0
        state.nextQuestionIndex = 1
      })
      .addCase(startTheoryTest.pending, (state) => {
        state.loading = true
        state.selectedQuestion = null
        state.currentQuestionIndex = 0
        state.previousQuestionIndex = 0
        state.nextQuestionIndex = 0
      })
      .addCase(startTheoryTest.rejected, (state) => {
        state.selectedQuestion = null
        state.loading = false
        state.currentQuestionIndex = 0
        state.previousQuestionIndex = 0
        state.nextQuestionIndex = 0
      })
      .addCase(getTheoryTestQuestions.fulfilled, (state, action) => {
        state.loading = false
        state.selectedQuestion = action.payload[0]
        state.currentQuestionIndex = 0
        state.previousQuestionIndex = 0
        state.nextQuestionIndex = 1
        state.questions = action.payload
      })
      .addCase(getTheoryTestQuestions.pending, (state) => {
        state.questions = []
        state.selectedQuestion = null
        state.loading = true
        state.currentQuestionIndex = 0
        state.previousQuestionIndex = 0
        state.nextQuestionIndex = 0
      })
      .addCase(getTheoryTestQuestions.rejected, (state) => {
        state.questions = []
        state.selectedQuestion = null
        state.loading = false
        state.currentQuestionIndex = 0
        state.previousQuestionIndex = 0
        state.nextQuestionIndex = 0
      })

      .addCase(getTheoryTestScore.fulfilled, (state, action) => {
        state.loading = false
        state.theoryTest = action.payload
      })
      .addCase(getTheoryTestScore.pending, (state) => {
        state.loading = true
        state.theoryTest = null
      })
      .addCase(getTheoryTestScore.rejected, (state) => {
        state.loading = true
        state.theoryTest = null
      })
      .addCase(eTestLogin.pending, (state) => {
        state.eTestUserToken = null
        state.loading = true
      })
      .addCase(eTestLogin.fulfilled, (state, action) => {
        state.eTestUserToken = action.payload
        state.error = null
        state.loading = false
      })
      .addCase(eTestLogin.rejected, (state, action) => {
        state.eTestUserToken = action.payload
        state.error = action.payload
        state.loading = false
      })
  }
})
export const { nextQuestion, previousQuestion, setAnswer } = ETestSlice.actions

export default ETestSlice.reducer
