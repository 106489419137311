import { lazy } from 'react'

const GeneratePaymentReceipt = lazy(() => import('../../views/receipts'))
const ReceiptPage = lazy(() => import('../../views/receipts/ReceiptPage'))

const PaymentReceiptRoutes = [
  {
    element: <GeneratePaymentReceipt />,
    path: '/payments/receipts'
  },
  {
    element: <ReceiptPage />,
    path: '/payments/receipts/:applicationNo'
  }
]

export default PaymentReceiptRoutes
