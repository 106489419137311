// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { sendAuthorizationRequest, postAccountInquiryRequest, postSendDebitRequest } from '@services/payment'

export const intiatePaymentAuthorization = createAsyncThunk('rma/intiatePaymentAuthorization', async (applicationNo) => {
  const response = await sendAuthorizationRequest(applicationNo)
  return response
})

export const sendAccountInquiryRequest = createAsyncThunk('rma/sendAccountInquiryRequest', async (data) => {
  const response = await postAccountInquiryRequest(data.applicationNo, data)
  return response
})

export const sendDebitRequest = createAsyncThunk('rma/sendDebitRequest', async (data) => {
  const response = await postSendDebitRequest(data.applicationNo, data)
  return response
})

export const rmaSlice = createSlice({
  name: 'rma',
  initialState: {
    rcResponse: null,
    ecResponse: null,
    acResponse: null,
    selectedBank: null,
    remitterAccountNo: null,
    status: 'idle',
    error: null
  },
  reducers: {
    selectBank: (state, action) => {
      state.selectedBank = action.payload
    },
    clearSelectedBank: (state) => {
      state.selectedBank = null
    },
    setRemitterAccountNo: (state, action) => {
      state.remitterAccountNo = action.payload
    },
    clearRemitterAccountNo: (state) => {
      state.remitterAccountNo = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(intiatePaymentAuthorization.pending, (state) => {
        state.rcResponse = null
        state.status = 'loading'
        state.error = null
      })
      .addCase(intiatePaymentAuthorization.fulfilled, (state, action) => {
        state.rcResponse = action.payload
        state.status = 'succeeded'
        state.error = null
      })
      .addCase(intiatePaymentAuthorization.rejected, (state, action) => {
        state.rcResponse = null
        state.status = 'failed'
        state.error = action.error
      })
      //sendAccountInquiryRequest
      .addCase(sendAccountInquiryRequest.pending, (state) => {
        state.ecResponse = null
        state.status = 'loading'
        state.error = null
      })
      .addCase(sendAccountInquiryRequest.fulfilled, (state, action) => {
        state.ecResponse = action.payload
        state.status = 'succeeded'
        state.error = null
      })
      .addCase(sendAccountInquiryRequest.rejected, (state, action) => {
        state.ecResponse = null
        state.status = 'failed'
        state.error = action.error
      })
      //send debit request
      .addCase(sendDebitRequest.pending, (state) => {
        state.acResponse = null
        state.status = 'loading'
        state.error = null
      })
      .addCase(sendDebitRequest.fulfilled, (state, action) => {
        state.acResponse = action.payload
        state.status = 'succeeded'
        state.error = null
      })
      .addCase(sendDebitRequest.rejected, (state, action) => {
        state.acResponse = null
        state.status = 'failed'
        state.error = action.error
      })
  }
})

export const { selectBank, clearSelectedBank, setRemitterAccountNo, clearRemitterAccountNo } = rmaSlice.actions

export default rmaSlice.reducer
