import { lazy } from 'react'

const SearchDriving = lazy(() => import('../../views/driving-license/search'))
const DrivingRenewalApplicationForm = lazy(() => import('../../views/driving-license/renewal/application'))
const DrivingRenewalApplicationDetailWizard = lazy(() => import('../../views/driving-license/renewal/application/DrivingRenewalApplicationDetailWizard'))
const DrivingRenewalPaymentWizard = lazy(() => import('../../views/driving-license/renewal/application/DrivingRenewalPaymentWizard'))
const ReplaceDriverLicense = lazy(() => import('../../views/driving-license/replacement/application'))
const DrivingReplacementApplicationDetailWizard = lazy(() => import('../../views/driving-license/replacement/application/DrivingReplacementApplicationDetailWizard'))
const DrivingReplacementPaymentWizard = lazy(() => import('../../views/driving-license/replacement/application/DrivingReplacementPaymentWizard'))
const DrivingTestDetailsWizard = lazy(() => import('../../views/driving-test/booking'))
const TestBookingFormWizard = lazy(() => import('../../views/driving-test/booking/TestBookingFormWizard'))
const TestBookingPaymentWizard = lazy(() => import('../../views/driving-test/booking/TestBookingPaymentWizard'))
const TestBookingDetailsWizard = lazy(() => import('../../views/driving-test/booking/TestBookingDetailsWizard'))
const ETestLogin = lazy(() => import('../../views/driving-test/e-test/ETestLogin'))
const ETestInstructionPage = lazy(() => import('../../views/driving-test/e-test/ETestInstructionPage'))
const QuizPage = lazy(() => import('../../views/driving-test/e-test/QuizPage'))
const ETestScore = lazy(() => import('../../views/driving-test/e-test/ETestScore'))

const DrivingTestRoutes = [
  {
    element: <DrivingTestDetailsWizard />,
    path: '/services/driving-test/book-test'
  },
  {
    element: <TestBookingFormWizard />,
    path: '/services/driving-test/book-test/:testConfigurationId'
  },
  {
    element: <TestBookingDetailsWizard />,
    path: '/services/driving-test/book-test/:testConfigurationId/:bookingId'
  },
  {
    element: <ETestLogin />,
    path: '/services/driving-test/e-test/login',
    meta: {
      layout: 'blank'
    }
  },
  {
    element: <ETestInstructionPage />,
    path: '/services/driving-test/e-test/instructions',
    meta: {
      layout: 'blank'
    }
  },
  {
    element: <QuizPage />,
    path: '/services/driving-test/e-test/:theoryTestId/quiz',
    meta: {
      layout: 'blank'
    }
  },
  {
    element: <ETestScore />,
    path: '/services/driving-test/e-test/:theoryTestId/score',
    meta: {
      layout: 'blank'
    }
  },
  {
    element: <TestBookingPaymentWizard />,
    path: '/services/driving-test/book-test/:testConfigurationId/:bookingId/make-payment'
  }
]

export default DrivingTestRoutes
