import { lazy } from 'react'

const SearchOffence = lazy(() => import('../../views/offence/search'))
const OffenceDetails = lazy(() => import('../../views/offence/application'))
const OffenceDetailsWizard = lazy(() => import('../../views/offence/application/OffenceDetailsWizard'))
const OffencePaymentWizard = lazy(() => import('../../views/offence/application/OffencePaymentWizard'))

const OffenceRoutes = [
  {
    element: <SearchOffence />,
    path: '/services/offence/search'
  },
  {
    element: <OffenceDetails />,
    path: '/services/offence/:offenceId/view'
  },
  {
    element: <OffencePaymentWizard />,
    path: '/services/offence/:applicationNo/make-payment'
  }
]

export default OffenceRoutes
