import { lazy } from 'react'

const SearchVehicles = lazy(() => import('../../views/vehicles/search'))
const RcRenewalApplication = lazy(() => import('../../views/vehicles/renewal/application'))
const RcRenewalApplicationDetailWizard = lazy(() => import('../../views/vehicles/renewal/application/RcRenewalApplicationDetailWizard'))
const RcRenewalPaymentWizard = lazy(() => import('../../views/vehicles/renewal/application/RcRenewalPaymentWizard'))
const ReplaceRegistrationCertificate = lazy(() => import('../../views/vehicles/replacement/application'))
const RcReplacementApplicationDetailWizard = lazy(() => import('../../views/vehicles/replacement/application/RcReplacementApplicationDetailWizard'))
const FitnessTest = lazy(() => import('../../views/vehicles/fitness/application'))
const RcReplacementPaymentWizard = lazy(() => import('../../views/vehicles/replacement/application/RcReplacementPaymentWizard'))

//Ownership Transfer
const OwnershipTransferApplication = lazy(() => import('../../views/vehicles/ownership-transfer'))
const OwnershipTransfer = lazy(() => import('../../views/vehicles/ownership-transfer/search/search'))
const AddOwnershipTransfer = lazy(() => import('../../views/vehicles/ownership-transfer/index'))
const AddIndividualOwnerShip = lazy(() => import('../../views/vehicles/ownership-transfer/individual/IndividualVehicleInfoCard'))
const AddOrganizationOwnerShip = lazy(() => import('../../views/vehicles/ownership-transfer/organization/OrganizationVehicleInfoCard'))
const OwnerShipAttachment = lazy(() => import('../../views/vehicles/ownership-transfer/Attachment'))
const OwnerShipPayment = lazy(() => import('../../views/vehicles/ownership-transfer/payment/Payment'))

//fitness
const RoadWorthinessApplication = lazy(() => import('../../views/vehicles/fitness/application'))
const RoadWorthinessPaymentWizard = lazy(() => import('../../views/vehicles/fitness/application/FitnessPaymentWizard'))

const VehicleRoutes = [
  {
    element: <SearchVehicles />,
    path: '/services/vehicles/search'
  },
  {
    element: <RcRenewalApplication />,
    path: '/services/vehicles/:id/renewal/new'
  },
  {
    element: <RcRenewalApplicationDetailWizard />,
    path: '/services/vehicles/:id/renewal/:applicationId/details'
  },
  {
    element: <RcRenewalPaymentWizard />,
    path: '/services/vehicles/:id/renewal/:applicationId/make-payment'
  },
  {
    element: <ReplaceRegistrationCertificate />,
    path: '/services/vehicles/:id/replacement/new'
  },
  {
    element: <RcReplacementApplicationDetailWizard />,
    path: '/services/vehicles/:id/replacement/:applicationId/details'
  },
  {
    element: <FitnessTest />,
    path: '/vehicles/fitness/application'
  },
  {
    element: <RcReplacementPaymentWizard />,
    path: '/services/vehicles/:id/replacement/:applicationId/make-payment'
  },
  //Ownership Transfer
  {
    element: <OwnershipTransferApplication />,
    path: '/services/vehicles/:id/ownership-transfer/new'
  },
  {
    path: '/vehicle/search/ownership-transfer',
    element: <OwnershipTransfer />
  },
  {
    path: '/vehicle/:id/ownership-transfer',
    element: <AddOwnershipTransfer />
  },
  {
    path: '/vehicle/ownership-transfer/:id/individual-transfer-details',
    element: <AddIndividualOwnerShip />
  },
  {
    path: '/vehicle/ownership-transfer/:id/organization-transfer-details',
    element: <AddOrganizationOwnerShip />
  },
  {
    path: '/vehicle/ownership-transfer/:id/attachment',
    element: <OwnerShipAttachment />
  },
  {
    path: '/vehicle/ownership-transfer/:applicationId/payment',
    element: <OwnerShipPayment />
  },
  //fitness
  {
    element: <RoadWorthinessApplication />,
    path: '/services/vehicles/:id/road-worthiness/new'
  },
  {
    element: <RoadWorthinessPaymentWizard />,
    path: '/services/vehicles/:id/fitness/:applicationId/make-payment'
  }
]

export default VehicleRoutes
