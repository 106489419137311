import mock from '../mock'
const data = {
  faqData: {
    // payment
    payment: {
      icon: 'Truck',
      title: 'Vehicle Services',
      subtitle: 'FAQ related to vehicle services',
      qandA: [
        {
          question: 'Documents required for vehicle registration?',
          ans: 'Forms I prescribed in the registration, copy of CID, import declaration and commercial invoice. Then report to Authority with the vehicle for physical verification'
        },
        {
          question: 'When shall I register my new motor vehicle?',
          ans: 'A person shall register a new motor vehicle with the Authority within 15 days from the date reflected in the invoice or date of customs clearance.'
        },
        {
          question: 'How do I replace my Registration Certificate?',
          ans: 'The Authority may issue a replacement of the registration certificate to the registered owner who opt for physical copy of registration certificate and upon payment of applicable fees prescribed in Schedule-I of this Regulations.'
        },
        {
          question: 'How do I change vehicle ownership?',
          ans: 'A person selling a vehicle shall, within 15 days of sale, apply for ownership transfer of the vehicle in the name of the person buying the vehicle.'
        },
        {
          question: 'How to do fitness test?',
          ans: 'A person shall not drive a motor vehicle without a valid roadworthiness certificate. The Authority shall inspect a motor vehicle for renewal of roadworthiness certificate as per the standard prescribed by the Authority'
        },
        {
          question: 'When do I need to renew my vehicle registration certificate?',
          ans: 'The owner of a motor vehicle shall renew the registration certificate on or before the date of expiry of its validity.'
        },
        {
          question: 'Process involved in the Sale of vehicle to a person outside Bhutan?',
          ans: 'The registered owner of a vehicle upon selling his vehicle to a person residing outside Bhutan shall within 15 days, surrender the registration certificate to the Authority and obtain no-objection certificate from the Authority to that effect upon payment of fee prescribed in Schedule-I of this Regulations.'
        }
      ]
    },
    // delivery
    delivery: {
      icon: 'CreditCard',
      title: 'Driving License',
      subtitle: 'FAQ related to driving license',
      qandA: [
        {
          question: 'Where has my order reached?',
          ans: 'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.'
        },
        {
          question:
            'The shipment status shows that it has been returned/cancelled. What does it mean and who do I contact?',
          ans: 'Sweet pie candy jelly. Sesame snaps biscuit sugar plum. Sweet roll topping fruitcake. Caramels liquorice biscuit ice cream fruitcake cotton candy tart. Donut caramels gingerbread jelly-o gingerbread pudding. Gummi bears pastry marshmallow candy canes pie. Pie apple pie carrot cake.'
        },
        {
          question: 'What if my shipment is marked as lost?',
          ans: 'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan.'
        },
        {
          question: 'My shipment status shows that it’s out for delivery. By when will I receive it?',
          ans: 'Cheesecake muffin cupcake dragée lemon drops tiramisu cake gummies chocolate cake. Marshmallow tart croissant. Tart dessert tiramisu marzipan lollipop lemon drops. Cake bonbon bonbon gummi bears topping jelly beans brownie jujubes muffin. Donut croissant jelly-o cake marzipan. Liquorice marzipan cookie wafer tootsie roll. Tootsie roll sweet cupcake.'
        },
        {
          question: 'What do I need to do to get the shipment delivered within a specific timeframe?',
          ans: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
        }
      ]
    },
    // cancellation and return
    cancellationReturn: {
      icon: 'Clipboard',
      title: 'Learner License',
      subtitle: 'FAQ related to learner license',
      qandA: [
        {
          question: 'Can my security guard or neighbour receive my shipment if I am not available?',
          ans: 'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.'
        },
        {
          question: 'How can I get the contact number of my delivery agent?',
          ans: 'Sweet pie candy jelly. Sesame snaps biscuit sugar plum. Sweet roll topping fruitcake. Caramels liquorice biscuit ice cream fruitcake cotton candy tart. Donut caramels gingerbread jelly-o gingerbread pudding. Gummi bears pastry marshmallow candy canes pie. Pie apple pie carrot cake.'
        },
        {
          question: 'How can I cancel my shipment?',
          ans: 'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan.'
        },
        {
          question: 'I have received a defective/damaged product. What do I do?',
          ans: 'Cheesecake muffin cupcake dragée lemon drops tiramisu cake gummies chocolate cake. Marshmallow tart croissant. Tart dessert tiramisu marzipan lollipop lemon drops. Cake bonbon bonbon gummi bears topping jelly beans brownie jujubes muffin. Donut croissant jelly-o cake marzipan. Liquorice marzipan cookie wafer tootsie roll. Tootsie roll sweet cupcake.'
        },
        {
          question: 'How do I change my delivery address?',
          ans: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
        },
        {
          question: 'What documents do I need to carry for self-collection of my shipment?',
          ans: 'At tempor commodo ullamcorper a lacus vestibulum. Ultrices neque ornare aenean euismod. Dui vivamus arcu felis bibendum. Turpis in eu mi bibendum neque egestas congue. Nullam ac tortor vitae purus faucibus ornare suspendisse sed. Commodo viverra maecenas accumsan lacus vel facilisis volutpat est velit. Tortor consequat id porta nibh. Id aliquet lectus proin nibh nisl condimentum id venenatis a. Faucibus nisl tincidunt eget nullam non nisi. Enim nunc faucibus a pellentesque. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus. Nec nam aliquam sem et tortor consequat id. Fringilla est ullamcorper eget nulla facilisi. Morbi tristique senectus et netus et.'
        },
        {
          question: 'What are the timings for self-collecting shipments from the Delhivery Branch?',
          ans: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Euismod lacinia at quis risus sed vulputate odio ut enim. Dictum at tempor commodo ullamcorper a lacus vestibulum.'
        }
      ]
    },
    // my orders
    myOrders: {
      icon: 'XCircle',
      title: 'Offence',
      subtitle: 'FAQ related to Offence',
      qandA: [
        {
          question: 'Can I avail of an open delivery?',
          ans: 'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.'
        },
        {
          question: 'I haven’t received the refund of my returned shipment. What do I do?',
          ans: 'Sweet pie candy jelly. Sesame snaps biscuit sugar plum. Sweet roll topping fruitcake. Caramels liquorice biscuit ice cream fruitcake cotton candy tart. Donut caramels gingerbread jelly-o gingerbread pudding. Gummi bears pastry marshmallow candy canes pie. Pie apple pie carrot cake.'
        },
        {
          question: 'How can I ship my order to an international location?',
          ans: 'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan.'
        },
        {
          question: 'I missed the delivery of my order today. What should I do?',
          ans: 'Cheesecake muffin cupcake dragée lemon drops tiramisu cake gummies chocolate cake. Marshmallow tart croissant. Tart dessert tiramisu marzipan lollipop lemon drops. Cake bonbon bonbon gummi bears topping jelly beans brownie jujubes muffin. Donut croissant jelly-o cake marzipan. Liquorice marzipan cookie wafer tootsie roll. Tootsie roll sweet cupcake.'
        },
        {
          question: 'The delivery of my order is delayed. What should I do?',
          ans: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
        }
      ]
    },
    // product and services
    productServices: {
      icon: 'FileText',
      title: 'Others',
      subtitle: 'Other FAQ',
      qandA: [
        {
          question: 'How can I register a complaint against the courier executive who came to deliver my order?',
          ans: 'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.'
        },
        {
          question: 'The status for my shipment shows as ‘not picked up’. What do I do?',
          ans: 'Sweet pie candy jelly. Sesame snaps biscuit sugar plum. Sweet roll topping fruitcake. Caramels liquorice biscuit ice cream fruitcake cotton candy tart. Donut caramels gingerbread jelly-o gingerbread pudding. Gummi bears pastry marshmallow candy canes pie. Pie apple pie carrot cake.'
        },
        {
          question: 'How can I get a proof of delivery for my shipment?',
          ans: 'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan.'
        },
        {
          question: 'How can I avail your services?',
          ans: 'Cheesecake muffin cupcake dragée lemon drops tiramisu cake gummies chocolate cake. Marshmallow tart croissant. Tart dessert tiramisu marzipan lollipop lemon drops. Cake bonbon bonbon gummi bears topping jelly beans brownie jujubes muffin. Donut croissant jelly-o cake marzipan. Liquorice marzipan cookie wafer tootsie roll. Tootsie roll sweet cupcake.'
        }
      ]
    }
  }
}

mock.onGet('/faq/data').reply(config => {
  const { q = '' } = config.params
  const queryLowered = q.toLowerCase()

  const filteredData = {}

  Object.entries(data.faqData).forEach(entry => {
    const [categoryName, categoryObj] = entry
    const filteredQAndAOfCategory = categoryObj.qandA.filter(qAndAObj => {
      return qAndAObj.question.toLowerCase().includes(queryLowered)
    })
    filteredData[categoryName] = {
      ...categoryObj,
      qandA: filteredQAndAOfCategory.length ? filteredQAndAOfCategory : []
    }
  })

  return [200, filteredData]
})
