import { LogIn, Package, Home, HelpCircle, Info, BookOpen, Truck, Compass, CreditCard, Layers } from 'react-feather'

import NdiLogoNoBg from '@src/assets/images/ndi/ndi-logo-bg.png'

const NDIIcon = ({ src, size }) => <img src={src} alt="icon" style={{ width: size, height: size, marginRight: '10px' }}  />

export default [
  {
    id: 'home',
    title: 'Home',
    icon: <Home size={20} />,
    navLink: '/',
    meta: {
      publicRoute: true
    }
  },
  {
    id: 'services',
    title: 'Services',
    icon: <Package size={20} />,
    navLink: '/services',
    meta: {
      publicRoute: true
    }
  },
  {
    id: 'pay-online',
    title: 'Pay Online',
    icon: <CreditCard size={20} />,
    navLink: '/pay-online/application/search',
    meta: {
      publicRoute: true
    }
  },
  {
    id: 'generate-receipt',
    title: 'Generate Receipt',
    icon: <Compass size={20} />,
    navLink: '/payments/receipts',
    meta: {
      publicRoute: true
    }
  },
  {
    id: 'etest-login',
    title: 'eTest Login',
    icon: <LogIn size={20} />,
    navLink: '/services/driving-test/e-test/login',
    meta: {
      publicRoute: true
    }
  },
  {
    id: 'NDI Services',
    title: 'National Digital Identity',
    icon: <NDIIcon size={20} src={NdiLogoNoBg} />,
    navLink: '/ndi-services',
    meta: {
      publicRoute: true
    }
  }
]
