// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import LearnerService from '@services/learner'

export const getLearner = createAsyncThunk('learner/getlearner', async (id, { rejectWithValue }) => {
  try {
    const response = await LearnerService.getLearner(id)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addPisIndividual = createAsyncThunk('learner/addPisIndividual', async (pisIndividual, { rejectWithValue }) => {
  try {
    const response = await LearnerService.addPisIndividual(pisIndividual)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addLearnerApplication = createAsyncThunk('learner/addLearnerApplication', async (learnerApplication, { rejectWithValue }) => {
  try {
    const response = await LearnerService.addLearnerApplication(learnerApplication)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const uploadAvatarPis = createAsyncThunk('learner/uploadAvatar', async (data, { rejectWithValue }) => {
  try {
    // const { pisId } = data.get('pisId')
    const response = await LearnerService.uploadPisAvatar(data)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateLearnerLicenseAttachment = createAsyncThunk('learner/updateLearnerLicenseAttachment', async (data, { rejectWithValue }) => {
  try {
    // const { pisId } = data.get('pisId')
    const response = await LearnerService.updateLearnerLicenseAttachment(data)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getOnlineApplicationById = createAsyncThunk('learner/getOnlineApplication', async (id, { rejectWithValue }) => {
  try {
    const response = await LearnerService.getOnlineApplicationById(id)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const LearnerSlice = createSlice({
  name: 'learner',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedLearner: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLearner.fulfilled, (state, action) => {
        state.selectedLearner = action.payload
        state.loading = false
      })
      .addCase(getLearner.pending, (state) => {
        state.selectedLearner = null
        state.loading = true
      })
      .addCase(getLearner.rejected, (state) => {
        state.selectedLearner = null
        state.loading = false
      })
  }
})

export default LearnerSlice.reducer
