// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { getPaymentDetailsByApplicationNo } from '@services/payment'

export const getPaymentByApplicationNo = createAsyncThunk('makePayment/getPaymentDetailsByApplicationNo', async (applicationNo) => {
  const response = await getPaymentDetailsByApplicationNo(applicationNo)
  return response
})

export const makePaymentSlice = createSlice({
  name: 'makePayment',
  initialState: {
    item: null,
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentByApplicationNo.pending, (state) => {
        state.item = null
        state.status = 'loading'
        state.error = null
      })
      .addCase(getPaymentByApplicationNo.fulfilled, (state, action) => {
        state.item = action.payload
        state.status = 'succeeded'
      })
      .addCase(getPaymentByApplicationNo.rejected, (state, action) => {
        state.item = null
        state.status = 'failed'
        state.error = action.error
      })
  }
})

export default makePaymentSlice.reducer
