import api from '@services'

const serviceBaseUrl = process.env.REACT_APP_DRIVING_TEST_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/driving-test/api`

import { stripEmptyObjects } from '@utils'

export const getOpenTestBookings = async (testFor) => {
  const response = await api.get(`${servicePrefix}/test-bookings/openings?testFor=${testFor}`)
  return response.data
}

export const getOpenTestBooking = async (testConfigurationId) => {
  const response = await api.get(`${servicePrefix}/test-bookings/openings/${testConfigurationId}`)
  return response.data
}

export const bookATest = async (data) => {
  console.log(data)
  console.log(stripEmptyObjects(data))
  const response = await api.post(`${servicePrefix}/test-bookings/book-a-test`, stripEmptyObjects(data))
  return response.data
}

export const getDriveTypes = async () => {
  const response = await api.get(`${servicePrefix}/drive-types/all`)
  return response.data
}

export const getTestBooking = async (id) => {
  const response = await api.get(`${servicePrefix}/test-bookings/${id}`)
  return response.data
}

export const getQuestions = async () => {
  const response = await api.get(`${servicePrefix}/questions`)
  return response.data
}

export const getTestBookingByApplicationNo = async (applicationNo) => {
  const response = await api.get(`${servicePrefix}/test-bookings/application-no/${applicationNo}`)
  return response.data
}

export const eTestLogin = async (data) => {
  const response = await api.post(`${servicePrefix}/theory-tests/login`, data)
  return response.data
}

export const startTheoryTest = async (apiKey) => {
  const response = await api.post(
    `${servicePrefix}/theory-tests/start-test`,
    {},
    {
      headers: {
        apiKey
      }
    }
  )
  return response.data
}

export const getTheoryTestQuestions = async (theoryTestId, apiKey) => {
  const response = await api.get(`${servicePrefix}/theory-tests/${theoryTestId}/questions`, {
    headers: {
      apiKey
    }
  })
  return response.data
}

export const getTheoryTestScore = async (theoryTestId, apiKey) => {
  const response = await api.get(`${servicePrefix}/theory-tests/${theoryTestId}/result`, {
    headers: {
      apiKey
    }
  })
  return response.data
}

export const submitTheoryTest = async (data, apiKey) => {
  const { id } = data

  console.log('what data', data)
  const response = await api.post(`${servicePrefix}/theory-tests/${id}/submit-test`, data, {
    headers: {
      apiKey
    }
  })
  return response.data
}

const DrivingTest = {
  getOpenTestBooking,
  bookATest,
  getQuestions,
  getDriveTypes,
  getTestBookingByApplicationNo,
  eTestLogin,
  startTheoryTest,
  getTheoryTestQuestions,
  getTheoryTestScore,
  submitTheoryTest
}

export default DrivingTest
