// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import VehicleService from '@services/vehicle'

export const getVehicle = createAsyncThunk('vehicles/getVehicle', async (id, { rejectWithValue }) => {
  try {
    const response = await VehicleService.getVehicle(id)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

//transfer ownership
export const addVehicleOwnershipTransfer = createAsyncThunk('vehicle/addVehicleOwnershipTransfer', async (transfer, { rejectWithValue }) => {
  try {
    const response = await VehicleService.addVehicleOwnershipTransfer(transfer)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

// export const getVehicle = createAsyncThunk('vehicle/getVehicle', async (id) => {
//   const response = await VehicleService.getVehicle(id)
//   // console.log(response)
//   return response
// })

export const updateVehicleOwnershipTransferAttachment = createAsyncThunk('vehicle/updateVehicleOwnershipTransferAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await VehicleService.updateVehicleOwnershipTransferAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const vehicleOwnershipTransferPayment = createAsyncThunk('vehicle/vehicleOwnershipTransferPayment', async (transfer) => {
  const response = await VehicleService.vehicleOwnershipTransferPayment(transfer)
  // console.log('conversion response', response)
  return response
})

export const vehiclesSlice = createSlice({
  name: 'vehicles',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedVehicle: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVehicle.fulfilled, (state, action) => {
        state.selectedVehicle = action.payload
        state.loading = false
      })
      .addCase(getVehicle.pending, (state) => {
        state.selectedVehicle = null
        state.loading = true
      })
      .addCase(getVehicle.rejected, (state) => {
        state.selectedVehicle = null
        state.loading = false
      })
  }
})

export default vehiclesSlice.reducer
