import { lazy } from 'react'

const SearchDriving = lazy(() => import('../../views/driving-license/search'))
const DrivingRenewalApplicationForm = lazy(() => import('../../views/driving-license/renewal/application'))
const DrivingRenewalApplicationDetailWizard = lazy(() => import('../../views/driving-license/renewal/application/DrivingRenewalApplicationDetailWizard'))
const DrivingRenewalPaymentWizard = lazy(() => import('../../views/driving-license/renewal/application/DrivingRenewalPaymentWizard'))
const ReplaceDriverLicense = lazy(() => import('../../views/driving-license/replacement/application'))
const DrivingReplacementApplicationDetailWizard = lazy(() => import('../../views/driving-license/replacement/application/DrivingReplacementApplicationDetailWizard'))
const DrivingReplacementPaymentWizard = lazy(() => import('../../views/driving-license/replacement/application/DrivingReplacementPaymentWizard'))
const TemporaryReceipt = lazy(() => import('../../views/receipts/TemporaryReceipt'))

const DrivingRoutes = [
  {
    element: <SearchDriving />,
    path: '/services/driving/search'
  },
  {
    element: <DrivingRenewalApplicationForm />,
    path: '/services/driving/:id/renewal/new'
  },
  {
    element: <DrivingRenewalApplicationDetailWizard />,
    path: '/services/driving/:id/renewal/:applicationId/details'
  },
  {
    element: <DrivingRenewalPaymentWizard />,
    path: '/services/driving/:id/renewal/:applicationId/make-payment'
  },
  {
    element: <ReplaceDriverLicense />,
    path: '/services/driving/:id/replacement/new'
  },
  {
    element: <DrivingReplacementApplicationDetailWizard />,
    path: '/services/driving/:id/replacement/:applicationId/details'
  },
  {
    element: <DrivingReplacementPaymentWizard />,
    path: '/services/driving/:id/replacement/:applicationId/make-payment'
  },
  {
    element: <TemporaryReceipt />,
    path: '/payments/receipts/driving-license/:applicationNo/renewal'
  }
]

export default DrivingRoutes
