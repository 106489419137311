import { lazy } from 'react'

const SearchLearner = lazy(() => import('../../views/learner-license/search'))
const LearnerNewApplication = lazy(() => import('../../views/learner-license/new-learner/index'))
const ApplicationForm = lazy(() => import('../../views/learner-license/new-learner/ApplicationForm'))
const Payment = lazy(() => import('../../views/learner-license/new-learner/Payment'))
const ApplicationDetails = lazy(() => import('../../views/learner-license/new-learner/ApplicationDetails'))

const LearnerRenewalApplication = lazy(() => import('../../views/learner-license/renewal/application'))
const LearnerRenewalApplicationDetailWizard = lazy(() => import('../../views/learner-license/renewal/application/LearnerRenewalApplicationDetailWizard'))
const LearnerRenewalPaymentWizard = lazy(() => import('../../views/learner-license/renewal/application/LearnerRenewalPaymentWizard'))
const ReplaceLearnerLicense = lazy(() => import('../../views/learner-license/replacement/application'))
const LearnerReplacementApplicationDetailWizard = lazy(() => import('../../views/learner-license/replacement/application/LearnerReplacementApplicationDetailWizard'))
const LearnerReplacementPaymentWizard = lazy(() => import('../../views/learner-license/replacement/application/LearnerReplacementPaymentWizard'))

const LearnerRoutes = [
  {
    element: <LearnerNewApplication />,
    path: '/services/learner/new'
  },
  {
    element: <ApplicationForm />,
    path: '/services/learner/new/:pisId/application'
  },
  {
    element: <ApplicationDetails />,
    path: '/services/learner/application/:id/summary'
  },
  {
    element: <Payment />,
    path: '/services/learner/:id/payment'
  },
  {
    element: <SearchLearner />,
    path: '/services/learner/search'
  },

  {
    element: <LearnerRenewalApplication />,
    path: '/services/learner/:id/renewal/new'
  },
  {
    element: <LearnerRenewalApplicationDetailWizard />,
    path: '/services/learner/:id/renewal/:applicationId/details'
  },
  {
    element: <LearnerRenewalPaymentWizard />,
    path: '/services/learner/:id/renewal/:applicationId/make-payment'
  },
  {
    element: <ReplaceLearnerLicense />,
    path: '/services/learner/:id/replacement/new'
  },
  {
    element: <LearnerReplacementApplicationDetailWizard />,
    path: '/services/learner/:id/replacement/:applicationId/details'
  },
  {
    element: <LearnerReplacementPaymentWizard />,
    path: '/services/learner/:id/replacement/:applicationId/make-payment'
  }
]

export default LearnerRoutes
